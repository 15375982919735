import Vue from 'vue'

export class MapInstance {

  static map = Vue.observable({
    map: null,
  });

  static init(map) {
    this.map = map
    this._addListeners()
  }

  static getMap() {
    return this.map;
  }

  static getZoom() {
    return this.map.getZoom();
  }

  /**
   * Условно закрытые методы
   * */
  static _addListeners() {
    this.map.on('moveend', (e) => {
      console.log('MAP Move end', e)
    })

    this.map.on('baselayerchange', (e) => {
      console.log('MAP Baselayer change', e)
    })

    this.map.on('mouseup', (e) => {
      console.log('MAP mouseup', e)
    })

    this.map.on('mouseout', (e) => {
      console.log('MAP mouseout', e)
    })

    this.map.on('click', (e) => {
      console.log('MAP click', e)
    })

    this.map.on('zoomend', (e) => {
      console.log('MAP zoomend', e)
    })
  }

}
